
import { defineComponent } from "vue";




export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
components: {

    }
});

