<template>
  <div>

<div>
<img style="width: 80%; margin-top: 15%" src="@/assets/vactextm.svg" />
  </div>

  <div>
    <router-link to="/m/vacancies">
<button class="btmvac">View all vacancies</button>
    </router-link>
  </div>

  <div>

<br /><br /><br /><br />
<div>
<img style="width: 85%;" src="@/assets/andvacm.png" />
<router-link to="/m/android">
<button class="btmvv2">More details</button>
</router-link>
</div>
<br /><br />
<div>
<img style="width: 85%;" src="@/assets/manvacm.png" />
<router-link to="/m/manager">
<button class="btmvv2">More details</button>
</router-link>
</div>
<br /><br />
<div>
<img style="width: 75%;
    margin-left: -13%;
" src="@/assets/asovacm.png" />
<router-link to="/m/aso">
<button class="btmvv2">More details</button>
</router-link>
</div>
<br /><br /><br /><br />
</div>
</div>
</template>

<script>


</script>

<style>
  .btmvac {
    color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 50%;
    background-color: white;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: bold;
    margin-left: -35%;
    }

    .btmvv2 {
        color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 35%;
    background-color: white;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: bold;
    margin-top: -10%;
    margin-left: 37%;
    display: block;
    }
</style>
