
import { defineComponent } from "vue";
import HeaderMobile from "@/components/Mobile/MainPageMobile/components/HeaderBarM.vue";
import LayoutPanelM from "@/components/Mobile/MainPageMobile/components/LayoutPanelM.vue"
import CompanyBarM from '@/components/Mobile/MainPageMobile/components/CompanyBarM.vue';
import AboutBarM from '@/components/Mobile/MainPageMobile/components/AboutBarM.vue';
import WorkingBarM from '@/components/Mobile/MainPageMobile/components/WorkingBarM.vue';
import VacanciesBarM from '@/components/Mobile/MainPageMobile/components/VacanciesBarM.vue';
import ProductBarM from '@/components/Mobile/MainPageMobile/components/ProductBarM.vue';
import FooterBarM from '@/components/Mobile/MainPageMobile/components/FooterBarM.vue';

export default defineComponent({
  name: "MainCore",
  props: {
    msg: String,
  },
components: {
HeaderMobile,
LayoutPanelM,
      CompanyBarM,
      AboutBarM,
     WorkingBarM,
      VacanciesBarM,
      ProductBarM,
      FooterBarM,
    }
});

