<template>
<div  id="section-two">
  <div style="background-color: #FAFAFA; height: 450px;"><br />
    <h1 style="margin-top: 2%; font-size: 25px; font-weight: bold;"  class="aboutlabel">Deus - over <span style="color: #ff2d55">50 products.</span> Latest releases</h1>



<div class="div1vac"><img style="width: 23%" src="@/assets/womanava.png" /><img class="vacp2" src="@/assets/womantext.svg" /> 

<a href="https://play.google.com/store/apps/details?id=com.avafitness.fitness&hl=us&gl=us"><img style="width: 14.4%;" class="uppers" src="@/assets/gp.svg" /></a>&nbsp;&nbsp;

<a href="https://ava.fitness/"><img class="uppersb" style="width: 14.4%;" src="@/assets/web.png" /></a>


<div>

</div>
</div>
<br />

</div>






</div>
</template>

<script></script>

<style>
.aboutlabel {
  text-align: left;
    margin-left: 10%;
}

.womanpic {
       border-radius: 15px;
    margin-left: 25%;
    width: 19%;

}

.womantext {
 margin-bottom: 7%;
    margin-left: 4%;
    width: 28%;
}

.div1vac{    
    width: 70%;
    min-height: 150px;
    float: left;
    margin-left: 14%;
    margin-right: auto;
    display: block;
}
.div2vac{    
        width: 70%;
    min-height: 150px;
    float: left;
    margin-left: 14%;
    margin-right: auto;
    display: block;
}

.vacp2 {
      width: 25%;
    margin-bottom: 15%;
    margin-left: 5%;
}

.uppers {
  margin-left: -25%;
    margin-bottom: 5%;
}

.uppersb {
    margin-bottom: 5%;
}
</style>
