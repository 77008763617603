<template>

  <div>

    <div class="headm">
<h1 style="font-family: sans-serif; margin-left: 5%;"><router-link to="/m"><span style="color: white">DEUS</span><span style="color: #ff2d55">MOBI</span></router-link></h1>
    </div>

  </div>
</template>

<script></script>

<style>
.headm {
  display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>
