<template>
  <div style="background-color: black" id="section-four">

<div class="main inline">
<div class="left-block">
  
<img class="p1" src="@/assets/logofooter.png" />

</div>
<div class="content">

<div style="text-align: left;
    font-size: 12.9px;
    margin-top: 11%;">
  <p style="color: grey">Email</p>
  <p style="color: white"><a style="color: white"  href="mailto:info@deus.mobi">info@deus.mobi</a></p>
<br />
  <p style="color: grey">Social Network</p>
  <a href="https://www.facebook.com/deusmobi">
  <img style="width: 45px" src="@/assets/fb.svg" />
  </a>
</div>
</div>
<div class="right-block">
<div style="text-align: left;
    font-size: 12.9px;
    margin-top: 30%;">
<p style="color: grey">Address</p>
<p style="color: white">Deus Mobile - FZCO</p>
<p style="color: white">29th Floor, Office C2, i-Rise Tower, Barsha Heights, Dubai</p>

<p style="color: grey">Telephone</p>

<p style="color: white">058 536 1731</p>
</div>
</div>
<div style="background-color: black">
<br /><br />

<table style="background-color: black" width="900" cellpadding="5" cellspacing="0" class="empic">
   <tr>
    <td width="300">
     
  
    <img style="width: 310px" src="@/assets/footer/1.png" />
      </td>



    <td width="300">


<a href="https://deus.mobi/privacy">
<img style="width: 80px" src="@/assets/footer/3.png" /></a>
    </td>


    <td width="300">

     <img style="width: 310px" src="@/assets/footer/2.png" />
 
      </td>



   </tr>
  </table>
<br /><br />
  </div>
</div>

  </div>
</template>

<script></script>

<style>

   
    .main {
        margin:0 auto; /* Центрируем блоки */
        width:100%; /* Задаем ширину 100%, чтобы сделать ее резиновой */
        
    }
    .inline div {
        display:inline-block; /* Делаем блоки по горизонтали */
        display: -moz-inline-stack; /* Для FireFox 2, тот же самый, что и inline-block */
        vertical-align:top; /* Выравниваем их сверху */
        font-size:16px; /* Ставим дочкам размер шрифта, иначе примет значение родителя */
        /* Для IE 6 и IE 7, чтобы блоки были по горизонтали
        zoom: 1;
        *display: inline;
        */
    }
    div.inline {font-size:0;} /* Ставим родителю значение 0, чтобы убрать отступы у блоков*/
    .left-block {
        background:black;
        width:20%; /*Задаем ширину в процентах чтобы сделать ее резиновой*/
        height:300px; /* Высота блока */
    }
    .content {
        background:black;
        width:60%;
        height:300px;
    }
    .right-block {
        background:black;
        width:20%;
        height:300px;
    }

.p1 {
      width: 70%;
    margin-top: 25%;
}

.p2 {
          width: 25%;
    margin-top: 12%;
}

.p3 {
      width: 70%;
    margin-top: 88%;
}

</style>
