<template>
  <div>

<img style="    width: 55%;
    margin-left: -25%;
}" src="@/assets/fa4.svg" />
<br />
<div style="text-align: left;
    font-size: 12.9px;
    margin-top: 11%;
    margin-left: 10%;">
  <p style="color: grey">Email</p>
  <p style="color: white"><a style="color: white"  href="mailto:info@deus.mobi">info@deus.mobi</a></p>
<br />

<p style="color: grey">Social Network</p>

<a href="https://www.facebook.com/deusmobi">
  <img style="width: 45px" src="@/assets/fb.svg" />
  </a>

<p style="color: grey">Address</p>
<p style="color: white">Deus Mobile - FZCO</p>
<p style="color: white">29th Floor, Office C2, i-Rise Tower, Barsha Heights, Dubai</p>

<p style="color: grey">Telephone</p>

<p style="color: white">058 536 1731</p>

</div>
<br /><br />
<img style="width: 85%" src="@/assets/what/1.png" />
<br /><br />
<a href="https://deus.mobi/privacy">
<img style="width: 23%; float: left;
    margin-left: 8%;" src="@/assets/what/2.png" /></a>
<br /><br />
<img style="width: 85%" src="@/assets/what/3.png" />
<br />
  </div>
</template>

<script></script>

<style>

 

</style>
