<template>
  <div>
<header class="site-header">
  <div class="site-identity">
    <h1 style="font-family: sans-serif"><router-link to="/"><span class="stsp">DEUS</span><span style="color: #ff2d55">MOBI</span></router-link></h1>
  </div>  
  <nav class="site-navigation">
    <ul class="nav">
      <li><a href="#" style="color: white" v-scroll-to="'#section-one'">About</a></li> 
      <li><a href="#" style="color: white" v-scroll-to="'#section-two'">Products</a></li> 
      <li><a href="#" style="color: white" v-scroll-to="'#section-three'">Career</a></li> 
      <li><a href="#" style="color: white" v-scroll-to="'#section-four'">Contact</a></li> 
    </ul>
  </nav>
</header>
  </div>
</template>

<script></script>

<style>
body {
  font-family: Helvetica;
  margin: 0;
}
a {
  text-decoration: none;
  color: #000;
}
.site-header { 
  padding: .5em 1em;
  display: flex;
  justify-content: space-between;
}

.site-identity h1 {
    font-size: 1.5em;
    margin: 1.6em 45%;
    display: inline-block;
}

.lg {
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
}

.navrefmain {
  color: white
}

.stsp {
  color: white;
}

.site-navigation ul, 
.site-navigation li {
  margin: 0; 
  padding: 0;
}

.site-navigation li {
  display: inline-block;
  margin: 1.4em 1em 1em 1em;
}
</style>
