<template>
  <div>
<header class="site-header">
  <div class="site-identity">
    <h1 style="font-family: sans-serif"><router-link to="/"><span style="color: black">DEUS</span><span style="color: #ff2d55">MOBI</span></router-link></h1>
  </div>  
  <nav class="site-navigation">
    <ul class="nav">
      <li><router-link to="/" v-scroll-to="'#section-one'">About</router-link></li> 
      <li><router-link to="/" v-scroll-to="'#section-two'">Products</router-link></li> 
      <li><router-link to="/" v-scroll-to="'#section-three'">Career</router-link></li> 
      <li><router-link to="/" v-scroll-to="'#section-four'">Contact</router-link></li> 
    </ul>
  </nav>
</header>

<img style="width: 80%" src="@/assets/teampicvac.svg"/>


  </div>
</template>

<script></script>

<style>
body {
  font-family: Helvetica;
  margin: 0;
}
a {
  text-decoration: none;
  color: #000;
}
.site-header { 
  padding: .5em 1em;
  display: flex;
  justify-content: space-between;
}

.site-identity h1 {
    font-size: 1.5em;
    margin: 1.6em 45%;
    display: inline-block;
}

.lg {
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
}

.navref {
  color: rgb(0, 0, 0)
}

.site-navigation ul, 
.site-navigation li {
  margin: 0; 
  padding: 0;
}

.site-navigation li {
  display: inline-block;
  margin: 1.4em 1em 1em 1em;
}
</style>
