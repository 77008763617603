<template>
<div>
<img style="width: 60%; margin-top: 8%;margin-bottom: 8%; float: left; margin-left: 10%;" src="@/assets/wid.png" /><br /><br /><br />
<div><aos-vue  data-aos="example-anim" animation="slide-down" :delay="1000"  :offset="100" :once="true" >
<img style="width: 80%; margin-top: 5%" src="@/assets/empic/1.png" />
</aos-vue>
<aos-vue  data-aos="example-anim"  animation="slide-down" :delay="1200"  :offset="100" :once="true" >
<img style="width: 80%; margin-top: 5%" src="@/assets/empic/22.png" />
</aos-vue>
<aos-vue  data-aos="example-anim"  animation="slide-down" :delay="1400"  :offset="100" :once="true" >
<img style="width: 80%; margin-top: 5%" src="@/assets/empic/33.png" />
</aos-vue>
<aos-vue data-aos="example-anim"  animation="slide-down" :delay="1600" :offset="100" :once="true" >
<img style="width: 80%; margin-top: 5%" src="@/assets/empic/44.png" />
</aos-vue>
  </div>

</div>
</template>

<script></script>

<style>



  

</style>

<style lang="scss">
[data-aos="example-anim"] {
animation: slide-down 1s linear both;
  opacity: 0;

  transition-property: transform, opacity;
  &.aos-animate {
animation: slide-down 1s linear both;

    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    visibility: hidden;
    height: 0%;
  }

  95% {
    visibility: visible;
    height: 50%;
  }

  /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
  100% {
    visibility: visible;
    height: auto;
  }
}
</style>