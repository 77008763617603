<template>
  <div>
    
    <div class="leftpnl">



<h1 style="margin-left: 15%; margin-top: 30%; font-size: 37px;">Deus Mobi -
Full Stack Mobile Agency</h1>
<p style="margin-left: 15%; margin-top: 10%">Develops and promotes mobile applications
on platforms iOS and Android</p>



    </div>

  <div class="rightpnl">


<img class="landingpic" src="@/assets/landingpic.png" />


    </div>
<br /><br /><br />
  </div>
</template>

<script></script>




<style >

.leftpnl{    
    width: 30%;
    min-height: 150px;
    margin-right: 70%;
    text-align: left;
    color: white
}
.rightpnl {    
    min-height: 150px;
      
}
.landingpic {
width: 35%;
    margin-left: 45%;
    margin-top: -22%;

}

</style>