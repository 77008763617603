
import { defineComponent } from "vue";
import Header from "@/components/Mobile/MainPageMobile/components/HeaderBarM.vue";
import VacanciesBar from "@/components/Mobile/VacanciesPageM/components/VacanciesBar.vue";
import FooterBar from "@/components/Mobile/MainPageMobile/components/FooterBarM.vue";

export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
components: {
Header,
VacanciesBar,
FooterBar
    }
});

