<template>
<div>
  
<div>
  <img style="width: 80%" src="@/assets/productm.png" />
</div>

<div><br /><br />
<a href="https://play.google.com/store/apps/details?id=com.avafitness.fitness&hl=us&gl=us"><img src="@/assets/gp.svg" /></a><br /><br />
<a href="https://ava.fitness/"><img src="@/assets/web.svg" /></a>
  </div>

</div>
</template>

<script>

</script>

<style>

</style>
