import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueScrollTo from 'vue-scrollto'
import 'animate.css';
import Quasar from 'quasar/src/vue-plugin.js';
import AosVue from "aos-vue";

createApp(App).use(AosVue).use(Quasar).use(store).use(router).use(VueScrollTo).mount("#app");