import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"font-family":"Montserrat"} }
const _hoisted_2 = { style: {"background":"linear-gradient(90deg, #4776E6 0%, #8E54E9 100%)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_LayoutPanel = _resolveComponent("LayoutPanel")!
  const _component_CompanyBar = _resolveComponent("CompanyBar")!
  const _component_AboutBar = _resolveComponent("AboutBar")!
  const _component_WorkingBar = _resolveComponent("WorkingBar")!
  const _component_VacanciesBar = _resolveComponent("VacanciesBar")!
  const _component_ProductBar = _resolveComponent("ProductBar")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header),
      _createVNode(_component_LayoutPanel)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_CompanyBar)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_AboutBar)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_WorkingBar)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_VacanciesBar)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ProductBar)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_FooterBar)
    ])
  ]))
}