
import { defineComponent } from "vue";
import Header from '@/components/MainPage/components/HeaderBar.vue';
import LayoutPanel from '@/components/MainPage/components/LayoutPanel.vue';
import CompanyBar from '@/components/MainPage/components/CompanyBar.vue';
import AboutBar from '@/components/MainPage/components/AboutBar.vue';
import WorkingBar from '@/components/MainPage/components/WorkingBar.vue';
import VacanciesBar from '@/components/MainPage/components/VacanciesBar.vue';
import ProductBar from '@/components/MainPage/components/ProductBar.vue';
import FooterBar from '@/components/MainPage/components/FooterBar.vue';
export default defineComponent({
  name: "MainCore",
  props: {
    msg: String,
  },
components: {
      Header,
      LayoutPanel,
      CompanyBar,
      AboutBar,
      WorkingBar,
      VacanciesBar,
      ProductBar,
      FooterBar,
    },



});



