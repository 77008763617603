<template>
  <div>

<div>
  <img style="margin-top: 20%;" src="@/assets/textlayoutm.svg" />
  </div>
  <br />

<br />
<div>
<img style="    width: 75%;
    margin-top: 10%;
    padding-bottom: 10%;
" src="@/assets/layoutpicm.png" />
</div>


  </div>
</template>

<script></script>




<style >

.btm {
    color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 40%;
    background-color: #D9D6FF;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: bold;
    margin-left: -45%;
    }

</style>