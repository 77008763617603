<template>
  <div>
    <img class="animspan" src="@/assets/companyline.png" />
  </div>
</template>

<script></script>

<style>
.complinem {
  width: 350%
}


.animspan {
    background-color: #efeff4;
    animation-duration: 15s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate;
 
  }
  
  @keyframes slidein {
    from {
      margin-left: -175%;
      width: 300%;
    }
  
    to {
      margin-left: -23%;
      width: 300%;
    }

    

  }
</style>
