
import { defineComponent } from "vue";
import VacCore from "@/components/Speedmatching/Terms/VacCore.vue"

export default defineComponent({
  name: "VacView",
  components: {
    VacCore,
  },
});
