import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainCore from "@/views/MainView.vue";
import VacView from "@/views/VacView.vue";

import VacAndroid from "@/views/VacAndroid.vue";
import Contacts from "@/views/Contacts.vue";
import VacASO from "@/views/VacASO.vue";
import VacManager from "@/views/VacManager.vue";


import MainCoreMobile from "@/views/MainViewMobile.vue";
import VacViewM from "@/views/VacViewM.vue";

import VacAndroidM from "@/views/VacAndroidM.vue";
import ContactsM from "@/views/ContactsM.vue";
import VacASOM from "@/views/VacASOM.vue";
import VacManagerM from "@/views/VacManagerM.vue";

import Privacy from "@/views/Redir.vue";
import PrivacyTwo from "@/views/RedirTwo.vue";
import Terms from "@/views/RedirThree.vue";

import SpeedTerms from "@/views/SpeedTerms.vue";
import SpeedPrivacy from "@/views/SpeedPrivacy.vue"

const routes: Array<RouteRecordRaw> = [
  
  {
    path: "/",
    name: "home",
    component: MainCore,
  },
  {
    path: "/vacancies",
    name: "vacancies",
    component: VacView,
  },

  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },

  {
    path: "/golive/privacy",
    name: "PrivacyThree",
    component: PrivacyTwo,
  },

  {
    path: "/golive/terms",
    name: "Terms",
    component: Terms,
  },

  {
    path: "/speedmeet/privacy",
    name: "PrivacyTwo",
    component: SpeedPrivacy,
  },

  {
    path: "/speedmeet/terms",
    name: "Speedetrms",
    component: SpeedTerms,
  },


  {
    path: "/android",
    name: "vacandroid",
    component: VacAndroid,
  },

  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },


  {
    path: "/aso",
    name: "vacaso",
    component: VacASO,
  },
  {
    path: "/manager",
    name: "vacmanager",
    component: VacManager,
  },





  {
    path: "/m",
    name: "mobile",
    component: MainCoreMobile,
  },

  {
    path: "/m/vacancies",
    name: "vacanciesm",
    component: VacViewM,
  },



  {
    path: "/m/android",
    name: "vacandroidm",
    component: VacAndroidM,
  },
  {
    path: "/m/aso",
    name: "vacasom",
    component: VacASOM,
  },
  {
    path: "/m/manager",
    name: "vacmanagerm",
    component: VacManagerM,
  },


  {
    path: "/m/contacts",
    name: "contactsm",
    component: ContactsM,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
