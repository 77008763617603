<template>
  <div>
    
    <div id="section-one">
    <h1 style="margin-top: 2%;"  class="aboutlabel">About</h1>
</div>

  <div>
<img style="width: 80%; margin-top: 1%;" src="@/assets/abouttext.svg" />
</div>



 

<table width="750" cellpadding="5" cellspacing="0" class="empic">
   <tr>
    <td width="150">
      <aos-vue data-aos="example-anim"  animation="slide-down"  :offset="200" :once="true" :duration="1000">
      <img  src="@/assets/abcard/1.png" style="width: 310px" />
    </aos-vue>
      </td>
  


    <td width="400">
        <aos-vue data-aos="example-anim"   animation="slide-down" :delay="300" :offset="200" :once="true" :duration="1000">
      <img src="@/assets/abcard/2.png"  style="width: 310px" />
    </aos-vue>
    
    </td>


    <td width="200">
      <aos-vue data-aos="example-anim"  animation="slide-down" :delay="600" :offset="200" :once="true" :duration="1000">
      <img src="@/assets/abcard/3.png"  style="width: 310px"  />
      </aos-vue>
     
      </td>



   </tr>
  </table>

  </div>
</template>

<script></script>



<style>

.aboutlabel {
  text-align: left;
    margin-left: 10%;
}

.stop1 {
animation-delay: 1s;
}

.stop2 {
animation-delay: 2s;
}

</style>
