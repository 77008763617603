
import { defineComponent } from "vue";
import Header from "@/components/VacASO/components/HeaderBar.vue";

import FooterBar from "@/components/VacanciesPage/components/FooterBar.vue";

export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
components: {
Header,

FooterBar
    }
});

