
import { defineComponent } from "vue";
import MainCoreMobile from "@/components/Mobile/MainPageMobile/MainCoreMobile.vue"

export default defineComponent({
  name: "MainViewMobile",
  components: {
    MainCoreMobile,
  },
});
