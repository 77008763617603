
import { defineComponent } from "vue";
import Header from "@/components/Mobile/MainPageMobile/components/HeaderBarM.vue";
import FooterBar from "@/components/Mobile/MainPageMobile/components/FooterBarM.vue";

export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
components: {
Header,

FooterBar
},
created() {
    // Check if the user is on a mobile device by checking the viewport width.
    if (window.innerWidth > 768) {
      // Redirect to the specified URL for mobile users.
      window.location.href = "/contacts";
    }
  },

    
});

