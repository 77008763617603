
import { defineComponent } from "vue";
import Header from "@/components/VacAndroid/components/HeaderBar.vue";
import FooterBar from "@/components/VacanciesPage/components/FooterBar.vue";

export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
  components: {
    Header,
    FooterBar,
  },
  methods: {
    moveAll() {
      const leftElement = document.querySelector(".left") as HTMLElement; // Type assertion
      if (leftElement) {
        leftElement.style.transform = "translateX(5%)";
      }

      const imgElement = document.querySelector(".right img") as HTMLImageElement; // Type assertion
      if (imgElement) {
        imgElement.style.transform = "translateX(-75%)";
      }
    },
    moveCenter() {
      const leftElement = document.querySelector(".left") as HTMLElement; // Type assertion
      if (leftElement) {
        leftElement.style.transform = "translateX(0)";
      }

      const imgElement = document.querySelector(".right img") as HTMLImageElement; // Type assertion
      if (imgElement) {
        imgElement.style.transform = "translateX(0)";
      }
    },
  },
  created() {
    // Check if the user is on a mobile device by checking the viewport width.
    if (window.innerWidth < 768) {
      // Redirect to the specified URL for mobile users.
      window.location.href = "/m/contacts";
    }
  },
});
