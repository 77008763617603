
import { defineComponent } from "vue";
import VacCoreM from "@/components/Mobile/VacASOM/VacCore.vue"

export default defineComponent({
  name: "VacViewM",
  components: {
    VacCoreM,
  },
});
