
import { defineComponent } from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import MainCore from "@/components/MainPage/MainCore.vue";

export default defineComponent({
  name: "MainView",
  components: {
    MainCore,
  },
  methods: {
    isMobile() {
      if (screen.width <= 600) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.isMobile()) {
      this.$router.push("/m");
    } else {
      console.log("Desktop found!");
    }
  },
});
