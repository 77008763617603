
import { defineComponent } from "vue";
import Header from "@/components/VacanciesPage/components/HeaderBar.vue";
import VacanciesBar from "@/components/VacanciesPage/components/VacanciesBar.vue";
import FooterBar from "@/components/VacanciesPage/components/FooterBar.vue";

export default defineComponent({
  name: "VacCore",
  props: {
    msg: String,
  },
components: {
Header,
VacanciesBar,
FooterBar
    }
});

