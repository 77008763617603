<template>
<div  id="section-three">
<div>
    <h1 style="margin-top: 2%;"  class="aboutlabel">Working in the <span style="color: #ff2d55">Deus Team</span></h1>
</div>

   <div>
     <!--
<img style="margin-top: 1%; width: 70%;" src="@/assets/workingbar.png" />
-->

   

<table width="750" cellpadding="5" cellspacing="0" class="empic">
   <tr>
    <td width="150">
     
      <img  src="@/assets/empic/tpic.svg" style="width: 360px" />
    
      </td>
    <td width="50">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>


    <td width="400">
        <aos-vue  data-aos="example-anim" animation="slide-down" :offset="200" :once="true" :duration="1000">
      <img src="@/assets/empic/1.png" style="width: 310px" />
    </aos-vue>
    <br />
     <aos-vue  data-aos="example-anim" :delay="300" animation="slide-down" :offset="200" :once="true" :duration="1000">
    <img src="@/assets/empic/22.png" style="width: 310px"  />
    </aos-vue>
    </td>


    <td width="200">
      <aos-vue  data-aos="example-anim" :delay="600" animation="slide-down" :offset="200" :once="true" :duration="1000">
      <img src="@/assets/empic/33.png" style="width: 280px"  />
      </aos-vue>
      <br />
      <aos-vue data-aos="example-anim" :delay="900" animation="slide-down"  :offset="200" :once="true" :duration="1000">
      <img src="@/assets/empic/44.png" style="width: 280px"  />
      </aos-vue>
      </td>



   </tr>
  </table>


</div>

</div>
</template>

<script></script>

<style>

.aboutlabel {
  text-align: left;
    margin-left: 10%;
}

.teampic {
width: 75%;
}

.empic {
      margin-left: auto;
    margin-right: auto;
}
  
  

</style>

<style lang="scss">
[data-aos="example-anim"] {
animation: slide-down 1s linear both;
  opacity: 0;

  transition-property: transform, opacity;
  &.aos-animate {
animation: slide-down 1s linear both;

    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    visibility: hidden;
    height: 0%;
  }

  95% {
    visibility: visible;
    height: 50%;
  }

  /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
  100% {
    visibility: visible;
    height: auto;
  }
}
</style>