<template>
  <div>
    <img class="compline" src="@/assets/companyline.png" />
  </div>
</template>

<script></script>

<style>
.compline {
  width: 100%
}
</style>
