<template>
  <div>
    <div>
 <img style="margin-top: 15%; width: 80%;" src="@/assets/abouttextm.svg" />
    </div>





<div>
<aos-vue data-aos="example-anim"  animation="slide-down" :delay="400"  :once="true" >
      <img  src="@/assets/abcard/1.png" style="width: 310px" />
    </aos-vue>
    <br />
<aos-vue data-aos="example-anim"   animation="slide-down" :delay="600"  :once="true" >
      <img src="@/assets/abcard/2.png"  style="width: 310px" />
    </aos-vue>
    <br />
    <aos-vue data-aos="example-anim"  animation="slide-down" :delay="800"  :once="true" >
      <img src="@/assets/abcard/3.png"  style="width: 310px"  />
      </aos-vue>
</div>
  </div>
</template>

<script></script>




<style>

.btmab {
    color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 40%;
    background-color: white;
    height: 35px;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: bold;
    margin-left: -45%;
    }

.stop1 {
animation-delay: 1s;
}

.stop2 {
animation-delay: 2s;
}

</style>
