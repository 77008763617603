<template>
  <div>
  <div>
    <h1 style="margin-top: 2%;"  class="aboutlabel">Vacancies</h1>
  </div>



<div class="mainv inlinev">
<div style="float: left;
    text-align: left;
    margin-left: 10%;" class="left-blockv">

<p style="width: 170%">Our friendly team knows how to work<br /> productively and have fun.</p>

<br />

<p>Join our fun<br />
and professional ranks!</p>
<br /><br />
<router-link to="/vacancies">
<button class="btnv7">View all vacancies</button>
</router-link>



</div>
<div class="right-blockv">
<div>
<img style="width: 20%" src="@/assets/androidpic.png" />
<div>
  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Android Developer</span><br />
  <router-link to="/android"><button class="btnv">More details</button></router-link>
  </div>
<br /><br />
  <img style="width: 20%" src="@/assets/asopic.png" />
<div>
  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ASA / ASO Manager</span><br />
  <router-link to="/aso"><button class="btnv">More details</button></router-link>
  </div>
<br /><br />
  <img style="width: 20%" src="@/assets/uampic.png" />
<div>
  <span style="font-size: 12px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User Acquisition Manager<br /> - Google Ads (UAC)</span><br />
  <router-link to="/manager"><button class="btnv">More details</button></router-link> <br /><br /><br />
  </div>                              
</div>
</div>
</div>





</div>
</template>

<script>


</script>

<style>
    .mainv {
        margin:0 auto; /* Центрируем блоки */
        width:100%; /* Задаем ширину 100%, чтобы сделать ее резиновой */
     
    }
    .inlinev div {
        display:inline-block; /* Делаем блоки по горизонтали */
        display: -moz-inline-stack; /* Для FireFox 2, тот же самый, что и inline-block */
        vertical-align:top; /* Выравниваем их сверху */
        font-size:16px; /* Ставим дочкам размер шрифта, иначе примет значение родителя */
        /* Для IE 6 и IE 7, чтобы блоки были по горизонтали
        zoom: 1;
        *display: inline;
        */
    }
    div.inlinev {font-size:0;} /* Ставим родителю значение 0, чтобы убрать отступы у блоков*/
    .left-blockv {
        
        width:20%; /*Задаем ширину в процентах чтобы сделать ее резиновой*/
        height:300px; /* Высота блока */
    }
    
    .right-blockv {
        
        width:50%;
        
    }

    .btnv {
      color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 70%;
    background-color: white;
    height: 30px;
    margin-top: 15%;
    }

    .btnv:hover {
      background-color: #ff2d55;
      color: white;
    }

.btnv7:hover {
      background-color: #ff2d55;
      color: white;
    }

    .btnv7 {
      color: #ff2d55;
    border-color: #ff2d55;
    border-radius: 10px;
    width: 70%;
    background-color: white;
    height: 30px;
    
    }
</style>
